/**
 * Uploads a file to the server using Next.js API route.
 *
 * @param {Blob} file - The file to be uploaded.
 * @param {string} [cartId] - Optional cart ID to associate with the file.
 * @returns {Promise<string>} - The URL of the uploaded file.
 * @throws {Error} - If the file upload fails.
 */
export const uploadBlobWithNextApi = async (file: Blob, cartId?: string): Promise<string> => {
  try {
    const formData = new FormData();
    const fileName = `customImages_${Date.now()}.zip`;
    const containerName = 'addtocart';

    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('containerName', containerName);
    formData.append('cartId', cartId || 'no-cart');

    const response = await fetch('/api/uploadBlob', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error uploading file: ${errorData.message}`);
    }

    const data = await response.json();
    return data.url;
  } catch (error) {
    throw new Error(`Error uploading file: ${(error as Error).message}`);
  }
};

/**
 * Uploads a file directly to Azure Blob Storage using a SAS token.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} [cartId] - Optional cart ID to associate with the file.
 * @returns {Promise<string>} - The URL of the uploaded file.
 * @throws {Error} - If the file upload fails.
 */
export const uploadBlobToAzure = async (file: File, cartId?: string): Promise<string> => {
  try {
    // Request SAS token from the server
    const response = await fetch('/api/generateSASToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cartId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to generate SAS token: ${errorData.message}`);
    }

    const { sasUrl } = await response.json();

    // Upload file to Azure Blob Storage using the SAS token
    const azureResponse = await fetch(sasUrl, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob',
      },
      body: file,
    });

    if (!azureResponse.ok) {
      const errorData = await azureResponse.json();
      throw new Error(`Error uploading file to Azure: ${errorData.message}`);
    }

    return sasUrl.split('?')[0];
  } catch (error) {
    throw new Error(`File upload failed: ${(error as Error).message}`);
  }
};
