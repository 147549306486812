import { SortOrder } from '@ariessolutionsio/react-ecomm-ui/dist/types/query/ProductQuery';
import { fetchApiHub } from 'frontastic';
import { SortRequest } from 'frontastic/provider/frontastic/UseProducts';

export const getSortedProducts = async (sort: SortRequest) => {
  const payload = {
    field: sort.field,
    order: sort.order ?? SortOrder.DESCENDING,
    category: sort.category,
  };
  const productResponse = await fetchApiHub(
    `/action/product/querySortedProducts`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );

  return productResponse;
};
