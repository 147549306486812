import { fetchApiHub } from 'frontastic';
import { SubscriptionForm } from 'frontastic/provider/frontastic/UseKlaviyo';

export const subscribeToNewsletter = async (payload: SubscriptionForm) => {
  try {
    const response = await fetchApiHub('/action/klaviyo/emailSubscription', { method: 'POST' }, payload);
    return response;
  } catch (error) {
    console.error('Error in subscribeToNewsletter:', error);
    throw error;
  }
};
