import { BlobUploadData } from '@Types/cart/CustomTin';
import JSZip from 'jszip';
import imageCompression from 'browser-image-compression';
import { fetchApiHub } from 'frontastic';
import {
  AccountConfirmationEmail,
  ContactUsEmail,
  CorpGiftSubmissionEmail,
  DSAREmail,
} from 'frontastic/provider/frontastic/UseAzure';

export const uploadBlobAsset = async (file: BlobUploadData) => {
  const payload = {
    fileName: file.fileName,
    containerName: file.containerName,
    base64Text: file.base64Text,
  };

  return await fetchApiHub('/action/azure/uploadBlobAsset', { method: 'POST' }, payload);
};

export const createZipFile = async (blobs: Blob[]) => {
  const zip = new JSZip();
  const options = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  await Promise.all(
    blobs.map(async (blob: any) => {
      const fileName = blob?.fileName ?? blob?.name;
      const isSVG = fileName?.toLowerCase().endsWith('.svg') ?? false;
      const compressedFile = !isSVG ? await imageCompression(blob, options) : blob;
      const blobData = await compressedFile.arrayBuffer();
      const blobName = blob.name;
      zip.file(blobName ?? blob.fileName, blobData);
    }),
  );

  const zipContent = await zip.generateAsync({
    type: 'blob',
  });
  return zipContent;
};

export const uploadBlob = async (blob: Blob) => {
  try {
    // const textContent = 'Hello, World fin test!';
    // const blobSample = new Blob([textContent], { type: 'text/plain' });
    // downloadBlob(blob, 'customImages.zip');
    const base64Text = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    const containerName = 'addtocart';
    const fileName = `customImages_${new Date().getTime()}.zip`;

    const payload = {
      containerName,
      fileName,
      base64Text,
    };
    const urlData = await fetchApiHub('/action/azure/uploadBlobAsset', { method: 'POST' }, payload);
    return urlData?.url;
  } catch (error) {
    console.error('Error uploading blob asset:', error);
    throw error;
  }
};

// this is the test function to download the blob
function downloadBlob(blob, fileName) {
  const link = document.createElement('a');

  const url = URL.createObjectURL(blob);
  link.href = url;

  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export const renameBlobAssetWithCart = async (url: string) => {
  const containerName = 'addtocart';
  try {
    const payload = {
      containerName,
      url,
    };
    const urlData = await fetchApiHub('/action/azure/renameBlobAssetWithCart', { method: 'POST' }, payload);
    return urlData.success ? urlData?.url : undefined;
  } catch (error) {
    console.error('Error copying blob asset:', error);
    throw error;
  }
};

// this will call the next js api
// export const uploadBlob = async (blob: Blob) => {
//     try {
//       const containerName = 'addtocart';
//       const fileName = `customImages_${new Date().getTime()}.zip`;
//       const formData = new FormData();
//       formData.append('containerName', containerName);
//       formData.append('fileName', fileName);
//       formData.append('file', blob);

//       // const urlData = await fetchApiHub('/action/azure/uploadBlobAsset', { method: 'POST' }, payload);
//       const response = await fetch('/api/uploadBlob', {
//         method: 'POST',
//         body: formData,
//       });
//       if (response.ok) {
//         const data = await response.json();
//         if (data?.url) {
//           return data?.url;
//         } else {
//           throw new Error('Error uploading blob');
//         }
//       } else {
//         const data = await response.body;
//         console.error('Error uploading blob:', data);
//         throw new Error('Error uploading blob');
//       }
//       return response?.url;
//     } catch (error) {
//       console.error('Error uploading blob asset:', error);
//       throw error;
//     }
//   };

export const sendAccountConfirmationEmail = async (payload: AccountConfirmationEmail) => {
  try {
    payload = {
      userName: payload.userName ?? '',
      userEmail: payload.userEmail ?? '',
      emailSubject: payload.emailSubject ?? 'Account Confirmation',
      resetPasswordLink: payload.resetPasswordLink ?? '#',
      viewInBrowserLink: payload.viewInBrowserLink ?? '#',
    };
    const emailResponse = await fetchApiHub('/action/azure/sendAccountConfirmationEmail', { method: 'POST' }, payload);
    return emailResponse;
  } catch (error) {
    console.error('Error sendAccountConfirmationEmail:', error);
    throw error;
  }
};

export const sendAccountVerificationEmail = async () => {
  try {
    const emailResponse = await fetchApiHub('/action/azure/sendAccountVerificationEmail', { method: 'POST' }, {});
    return emailResponse;
  } catch (error) {
    console.error('Error sendAccountVerificationEmail:', error);
    throw error;
  }
};

export const sendCorpGiftSubmissionEmail = async (payload: CorpGiftSubmissionEmail) => {
  try {
    payload = {
      ...payload,
      emailSubject: payload.emailSubject ?? 'Corporate Gifting Submission',
    };
    const emailResponse = await fetchApiHub('/action/azure/sendCorpGiftSubmissionEmail', { method: 'POST' }, payload);
    return emailResponse;
  } catch (error) {
    console.error('Error sendCorpGiftSubmissionEmail:', error);
    throw error;
  }
};

export const sendContactUsEmail = async (payload: ContactUsEmail) => {
  try {
    payload = {
      ...payload,
      emailSubject: payload.emailSubject ?? 'Contact Us Form',
    };
    const emailResponse = await fetchApiHub('/action/azure/sendContactUsEmail', { method: 'POST' }, payload);
    return emailResponse;
  } catch (error) {
    console.error('Error sendContactUsEmail:', error);
    throw error;
  }
};

export const sendDSAREmail = async (payload: DSAREmail) => {
  try {
    payload = {
      ...payload,
      emailSubject: payload.emailSubject ?? 'DSAR Request',
    };
    const emailResponse = await fetchApiHub('/action/azure/sendDSAREmail', { method: 'POST' }, payload);
    return emailResponse;
  } catch (error) {
    console.error('Error sendDSAREmail:', error);
    throw error;
  }
};
