import { ComposableCommerce, ComposableCommerceEvents } from '@commercetools/frontend-composable-commerce';
import { SDK } from '@commercetools/frontend-sdk';
import { mapLanguage } from '../project.config';

class CommercetoolsSDK extends SDK<ComposableCommerceEvents> {
  composableCommerce!: ComposableCommerce;
  constructor() {
    super();
    this.composableCommerce = new ComposableCommerce(this);

    this.on('errorCaught', (event) => {
      console.log('SDK error: ', event.data);
    });
    /* this.on('errorCaught', (event) => {
			console.log('SDK error: ', event.data);
		}); */
  }

  configureForNext(nextJsLocale: string) {
    const localizationData = mapLanguage(nextJsLocale);
    if (localizationData) {
      sdk.configure({
        locale: localizationData,
        currency: 'USD',
        extensionVersion: process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
        endpoint: process.env.NEXT_PUBLIC_FRONTASTIC_HOST
          ? process.env.NEXT_PUBLIC_FRONTASTIC_HOST.split('/frontastic')[0]!
          : '',
      });
    }

    sdk.configure({
      locale: 'en_GB',
      currency: 'USD',
      extensionVersion: process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
      endpoint: process.env.NEXT_PUBLIC_FRONTASTIC_HOST
        ? process.env.NEXT_PUBLIC_FRONTASTIC_HOST.split('/frontastic')[0]!
        : '',
    });
  }
}

const sdk = new CommercetoolsSDK();
export { sdk };
