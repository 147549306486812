import { Address } from '@Types/account/Address';
import { OrderSearchInput } from '@Types/cart/Cart';
import { fetchApiHub } from 'frontastic';

export type OrderDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
};

export type OrderReq = {
  limit?: number;
};

export const getOrderById = async (orderId: string) => {
  const orderResponse = await fetchApiHub(`/action/order/getOrderById?orderId=${orderId}`, {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'GET',
  });
  return orderResponse;
};

export const getOrderByNumber = async (orderNumber: string) => {
  const orderResponse = await fetchApiHub(`/action/order/getOrderByNumber?orderNumber=${orderNumber}`, {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'GET',
  });
  return orderResponse;
};

export const getOrdersByUserId = async ({ limit }: OrderReq) => {
  const orderResponse = await fetchApiHub(`/action/order/getOrdersByUserId?limit=${limit}`, {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'GET',
  });
  return orderResponse;
};

export const checkOrderForOneTimeDiscountCode = async ({ email, phoneNumber, discountCodeId }: OrderSearchInput) => {
  const orderResponse = await fetchApiHub(`/action/order/checkOrderForOneTimeDiscountCode`, {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ email, phoneNumber, discountCodeId }),
  });
  return orderResponse;
};
