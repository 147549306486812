import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface LoadingContextType {
  globalLoading: boolean;
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const LoadingContext = createContext<LoadingContextType>({
  globalLoading: false,
  setGlobalLoading: null,
});

interface LoadingProviderProps {
  children: ReactNode;
}

export function LoadingProvider({ children }: LoadingProviderProps) {
  const [globalLoading, setGlobalLoading] = useState(false);
  const value: LoadingContextType = { globalLoading, setGlobalLoading };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (globalLoading) {
      // Set globalLoading to false after 20 seconds
      timeoutId = setTimeout(() => {
        setGlobalLoading(false);
      }, 20000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [globalLoading]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading(): LoadingContextType {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
