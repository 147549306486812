import cookieCutter from 'cookie-cutter';
import { deleteCookie } from 'cookies-next';

export const clearSession = async (cookieIdenfier: string) => {
  deleteCookie(cookieIdenfier);
};

export const clearAllLocalData = async () => {
  localStorage.clear();
};
