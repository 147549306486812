import React from 'react';
import { Toaster as ReactToaster } from 'react-hot-toast';

const Toaster = () => {
  return (
    <ReactToaster
      toastOptions={{
        duration: 8000,
      }}
    />
  );
};

export default Toaster;
