import { Address } from '@Types/account/Address';
import { CartAddress, CartAddressRequest } from '@Types/cart/AddressInfo';
import { Cart } from '@Types/cart/Cart';
import { CustomerContactInfo } from '@Types/cart/CustomerInfo';
import { CartShippingMethod, LineShippingMethod } from '@Types/cart/CustomShippingMethod';
import { Discount } from '@Types/cart/Discount';
import { Variant } from '@Types/product/Variant';
import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export type CartDetails = {
  account?: { email: string; phoneNumber?: string };
  shipping?: Address;
  billing?: Address;
};

const getCustomHeaders = () => {
  return {
    'coFE-Custom-Configuration': JSON.stringify({
      isCartOrCheckoutPage:
        window.location.pathname.includes('/cart') || window.location.pathname.includes('/checkout'),
      hostName: window.location.href,
    }),
  };
};

// Avoiding 500 error for larger payloads
// Not used(we may need it later)
export const getCartIfFail = async () => {
  let res = null;
  try {
    res = await fetchApiHub('/action/custom/getCart', {
      method: 'POST',
      headers: getCustomHeaders(),
    });
  } catch (error) {
    console.error('Error while getting cart for time-out scenario');
    return {
      error: true,
    };
  }
  if (!res.infoError) {
    return res;
  } else {
    return {
      error: true,
    };
  }
};

export const cartItems = () => {
  return useSWR(
    '/action/custom/getCart',
    () =>
      fetchApiHub('/action/custom/getCart', {
        method: 'POST',
        headers: getCustomHeaders(),
      }),
    revalidateOptions,
  );
};

// Loading cart code it may needed  to add loading spinner
// export const cartItems = () => {
//     const result = useSWR(
//       '/action/custom/getCart',
//       () =>
//         fetchApiHub('/action/custom/getCart', {
//           method: 'POST',
//           headers: getCustomHeaders(),
//         }),
//       revalidateOptions,
//     );
//     if (result?.data) return { data: result.data, loading: false };
//     return {
//       data: {},
//       loading: true,
//     };
//   };

export const queryProduct = () => {
  return useSWR(
    '/action/product/query',
    () =>
      fetchApiHub('/action/custom/getCart', {
        method: 'POST',
        headers: getCustomHeaders(),
      }),
    revalidateOptions,
  );
};

export const addItem = async (variant: Variant, quantity: number, store?: string) => {
  const payload = {
    lineItemId: variant?.sku,
    quantity,
    variantId: variant?.id,
    productId: variant?.id,
    skuId: variant?.sku,
    personalizedImage: variant?.personalizedImage,
    store,
  };

  if (!store) {
    delete payload.store;
  }

  let res = null;
  try {
    res = await fetchApiHub(
      '/action/custom/addToCart',
      {
        method: 'POST',
        headers: getCustomHeaders(),
      },
      payload,
    );
  } catch (error) {
    return;
  }
  if (!res.infoError) {
    mutate('/action/custom/getCart', res, { revalidate: false });
  }
};

export const checkout = async () => {
  throw new Error('Method not implemented.');
  //   const res = await fetchApiHub('/action/cart/checkout', {
  //     method: 'POST',
  //   });
  //   mutate('/action/cart/getCart', res);
};

export const getProjectSettings = async () => {
  throw new Error('Method not implemented.');
  // return await fetchApiHub('/action/project/getProjectSettings');
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/custom/getShippingMethods');
};

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  const res = await fetchApiHub(
    '/action/custom/removeLineItem',
    {
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  mutate('/action/custom/getCart', res, { revalidate: false });
};

export const updateItem = async (cartLineItemId: string, newQuantity: number, store?: string) => {
  const payload = {
    lineItemId: cartLineItemId,
    quantity: newQuantity,
    store,
  };
  const res = await fetchApiHub(
    '/action/custom/updateLineItem',
    {
      method: 'PUT',
      headers: getCustomHeaders(),
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
  }
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  //throw new Error('Method not implemented.');
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/custom/getCart', res, { revalidate: false });
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  throw new Error('Method not implemented.');
  //   const payload = {
  //     cartShippingMethod: {
  //       addressKey: shippingInput?.addressKey,
  //       shippingMethod: shippingInput?.shippingMethod,
  //       shipByDate: shippingInput?.shipByDate,
  //     },
  //   };

  //   const res = await fetchApiHub(
  //     `/action/custom/setShippingMethod`,
  //     {
  //       headers: {
  //         accept: 'application/json',
  //       },
  //       credentials: 'include',
  //       method: 'POST',
  //     },
  //     payload,
  //   );
  //   mutate('/action/custom/getCart', res, { revalidate: false });
};

export const setCartShippingMethod = async (shippingInput: CartShippingMethod) => {
  const payload = {
    cartShippingMethod: {
      addressKey: shippingInput?.addressKey,
      shippingMethod: shippingInput?.shippingMethod,
      shipByDate: shippingInput?.shipByDate,
      giftMessage: shippingInput?.giftMessage,
    },
  };

  const res = await fetchApiHub(
    `/action/custom/setShippingMethod`,
    {
      headers: getCustomHeaders(),
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
  }
};

export const setMultiShippingMethod = async (shippingInput: LineShippingMethod[]) => {
  const payload = {
    lineShippingMethods: shippingInput.map((item) => {
      return {
        addressKey: item?.addressKey,
        shippingMethod: item?.shippingMethod ?? 'standard',
        shipByDate: item?.shipByDate,
        itemId: item?.itemId,
        qty: item?.quantity,
        giftMessage: item?.giftMessage ?? '',
      };
    }),
  };

  const res = await fetchApiHub(
    `/action/custom/setShippingMethod`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
  }
  // This code is used to bypass the CoFe for setShippingMethod for larger payload, we may need in future.
  //}
  //mutate('/action/custom/getCart', res, { revalidate: false });
  //   const response = await fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {});
  //   if (response.customCartId) {
  //     fetch('/api/setShippingApi', {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         payload,
  //         cartId: response.customCartId,
  //         token: response.token,
  //       }),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then(async (data) => {
  //         console.log('fin rese', data);
  //         if (data.success) {
  //           const cart = await fetchApiHub(
  //             '/action/custom/buildWithAvailableShippingAndAddress',
  //             { method: 'POST' },
  //             { cart: data.data },
  //           );
  //           console.log('cart', cart);
  //           mutate('/action/custom/getCart', cart, { revalidate: false });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Unable to set shipping method at this time:', error);
  //       });
  //   } else {
  //     console.error('Unable to set shipping method at this time:');
  //   }
};

export const addCustomerInformation = async (customerInformation: CustomerContactInfo) => {
  const payload = {
    emailAddress: customerInformation?.emailAddress,
    phoneNumber: customerInformation?.phoneNumber,
  };
  const res = await fetchApiHub(
    `/action/custom/addCustomerInformation`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
    return res;
  }
};

export const addCartAddress = async (address: CartAddress) => {
  let payload = {
    firstName: address?.firstName,
    lastName: address?.lastName,
    addressType: address?.addressType ?? 'SHIPPING',
    addressStreet: address?.street,
    addressSubPremise: address?.subPremise,
    company: address?.company,
    addressCountry: address?.addressCountry ?? 'US',
    addressPostalCode: address?.zipCode,
    addressLocality: address?.city,
    addressProvince: address?.state,
  } as CartAddressRequest;

  if (address.addressKey) {
    payload = {
      ...payload,
      customerRecordAddressKey: address.addressKey,
    };
  }
  const res = await fetchApiHub(
    `/action/custom/addAddress`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  mutate('/action/custom/getCart', res, { revalidate: false });
  return res;
};

export const addBillingAddress = async (address: CartAddress) => {
  let payload = {
    firstName: address?.firstName,
    lastName: address?.lastName,
    addressStreet: address?.street,
    addressSubPremise: address?.subPremise,
    company: address?.company,
    addressCountry: address?.addressCountry ?? 'US',
    addressPostalCode: address?.zipCode,
    addressLocality: address?.city,
    addressProvince: address?.state,
  } as CartAddressRequest;

  if (address.addressKey) {
    payload = {
      ...payload,
      customerRecordAddressKey: address.addressKey,
    };
  }
  const res = await fetchApiHub(
    `/action/custom/addBillingAddress`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  mutate('/action/custom/getCart', res, { revalidate: false });
  return res;
};

export const addShippingAddresses = async (addresses: CartAddress[]) => {
  const payload: CartAddressRequest[] = addresses.map((address) => ({
    firstName: address?.firstName,
    lastName: address?.lastName,
    addressStreet: address?.street,
    addressSubPremise: address?.subPremise,
    company: address?.company,
    addressCountry: address?.addressCountry ?? 'US',
    addressPostalCode: address?.zipCode,
    addressLocality: address?.city,
    addressProvince: address?.state,
    customerRecordAddressKey: address.addressKey ?? undefined,
  }));
  const res = await fetchApiHub(
    `/action/custom/addShippingAddress`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
    return res;
  }
};

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  };
  const res = await fetchApiHub(
    `/action/custom/redeemDiscount`,
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  if (res.infoError) {
    return res;
  } else {
    mutate('/action/custom/getCart', res, { revalidate: false });
    return res;
  }
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    code: discount.code,
  };
  const res = await fetchApiHub(
    '/action/custom/removeDiscount',
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  mutate('/action/custom/getCart', res, { revalidate: false });
};

export const submitOrder = async (id: string) => {
  const payload = {
    id: id,
  };
  const res = await fetchApiHub(
    '/action/custom/submitOrder',
    {
      credentials: 'include',
      method: 'POST',
      headers: getCustomHeaders(),
    },
    payload,
  );
  return res;
};

export const clearCartAndSession = async () => {
  /**FIX: Randomly an error occurs when try to revalidate the cache and send an unexpected Error. This is not a final fix and need digging more details */
  try {
    mutate('/action/custom/getCart', {}, { revalidate: false });
  } catch (err) {
    console.error('Error while clearing cart and session', err);
  }
};
