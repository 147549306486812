import * as React from 'react';
import { cn } from '@ariessolutionsio/ui';
import * as CSS from 'csstype';

export function Grid({
  children,
  wrapperClassName,
  gridClassName,
  fullBleed,
  staticPage,
}: {
  children: React.ReactNode;
  gridClassName?: string;
  wrapperClassName?: string;
  fullBleed?: boolean;
  staticPage?: boolean;
}) {
  const gridCss: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
    columnGap: '24px',
    margin: '0',
  };
  return (
    <div
      className={cn(
        fullBleed
          ? `p-0 ${wrapperClassName}`
          : staticPage
          ? `mx-auto w-full max-w-[1440px] grow md:px-[60px]`
          : `lg:px-auto ${wrapperClassName}`,
      )}
    >
      <div className={gridClassName} style={gridCss}>
        {children}
      </div>
    </div>
  );
}
