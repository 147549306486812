import { defaultDesignTheme, DesignTheme } from '@ariessolutionsio/react-ecomm-ui/dist/utils/theme';

const _defaultTheme: DesignTheme = defaultDesignTheme;

_defaultTheme.typography.variants.h1 = ['text-[54px]', 'md:text-[65px]', 'font-bold'];
_defaultTheme.typography.variants.h2 = ['text-[32px]', 'md:text-[52px]', 'font-bold'];
_defaultTheme.typography.variants.h3 = ['text-[24px]', 'md:text-[36px]', 'font-bold'];
_defaultTheme.typography.variants.h4 = ['text-[32px]', 'md:text-[40px]'];
_defaultTheme.typography.variants.h5 = ['text-[20px]', 'md:text-[24px]'];
_defaultTheme.typography.variants.h6 = ['text-[16px]', 'md:text-[20px]'];
export const typographyTheme = _defaultTheme;
