import { UseAccount } from './UseAccount';
import { UseAdyen } from './UseAdyen';
import { UseAzure } from './UseAzure';
import { UseBlobStorage } from './UseBlobStorage';
import { UseCart } from './UseCart';
import { UseKlaviyo } from './UseKlaviyo';
import { UseOrder } from './UseOrder';
import { UseProducts } from './UseProducts';
import { UseWishlist } from './UseWishlist';
import {
  getAccount,
  changePassword,
  getRequestResetLink,
  confirm,
  requestConfirmationEmail,
  login,
  logout,
  register,
  requestPasswordReset,
  resetPassword,
  update,
  updateStoreAccount,
  addAddress,
  updateAddress,
  removeAddress,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  generateUniqueIdNumber,
} from '../../actions/account';
import { createSession } from '../../actions/adyen';
import {
  uploadBlobAsset,
  createZipFile,
  renameBlobAssetWithCart,
  uploadBlob,
  sendAccountConfirmationEmail,
  sendCorpGiftSubmissionEmail,
  sendContactUsEmail,
  sendAccountVerificationEmail,
  sendDSAREmail,
} from '../../actions/azure';
import {
  cartItems,
  addItem,
  checkout,
  removeItem,
  getShippingMethods,
  setShippingMethod,
  setCartShippingMethod,
  setMultiShippingMethod,
  addCustomerInformation,
  addCartAddress,
  updateCart,
  updateItem,
  redeemDiscountCode,
  removeDiscountCode,
  getProjectSettings,
  submitOrder,
  clearCartAndSession,
  addShippingAddresses,
  addBillingAddress,
} from '../../actions/cart';
import { subscribeToNewsletter } from '../../actions/klaviyo';
import {
  getOrderById,
  getOrderByNumber,
  getOrdersByUserId,
  checkOrderForOneTimeDiscountCode,
} from '../../actions/order';
import { getSortedProducts } from '../../actions/product/product-actions';
import { getWishlist, addToWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist';
import { uploadBlobToAzure } from '../../lib/uploadFileToAzure';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useAzure: UseAzure;
  useBlobStorage: UseBlobStorage;
  useOrder: UseOrder;
  useProducts: UseProducts;
  useKlaviyo: UseKlaviyo;
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      addItem,
      updateCart,
      removeItem,
      updateItem,
      getShippingMethods,
      setShippingMethod,
      setCartShippingMethod,
      setMultiShippingMethod,
      addCustomerInformation,
      addCartAddress,
      checkout,
      getProjectSettings,
      redeemDiscountCode,
      removeDiscountCode,
      submitOrder,
      clearCartAndSession,
      addShippingAddresses,
      addBillingAddress,
    },
    useAccount: {
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      updateStoreAccount,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      generateUniqueIdNumber,
      getRequestResetLink,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
    useAzure: {
      uploadBlobAsset,
      sendAccountConfirmationEmail,
      sendCorpGiftSubmissionEmail,
      sendContactUsEmail,
      sendAccountVerificationEmail,
      sendDSAREmail,
    },
    useBlobStorage: {
      createZipFile,
      uploadBlob,
      renameBlobAssetWithCart,
      uploadBlobToAzure,
    },
    useOrder: {
      getOrderById,
      getOrderByNumber,
      getOrdersByUserId,
      checkOrderForOneTimeDiscountCode,
    },
    useProducts: {
      getSortedProducts,
    },
    useKlaviyo: {
      subscribeToNewsletter,
    },
  };
};
