import { defaultDesignTheme, DesignTheme } from '@ariessolutionsio/react-ecomm-ui/dist/utils/theme';
import { typographyTheme } from './typography';

let _defaultTheme: DesignTheme = defaultDesignTheme;

_defaultTheme = {
  ...typographyTheme,
};

_defaultTheme.palette.black.textOutlined = [
  'text-black',
  'ring-[#2B2A2D]',
  'hover:text-white',
  'hover:bg-[#2B2A2D]',
  'font-bold',
];
_defaultTheme.components.buttonLink.size.md = ['text-xs', 'px-5', 'py-3', 'uppercase'];
_defaultTheme.components.productCard.container = [
  'group',
  'relative',
  'flex',
  'flex-col',
  'overflow-hidden',
  'bg-white',
];

// Product card styling
_defaultTheme.components.productCard.price = [
  'text-[#69676D]',
  'xs:text-[14px]',
  'md:text-[18px]',
  'font-normal',
  'leading-[28.8px]',
];
_defaultTheme.components.productCard.title = [
  'font-bold',
  'text-[16px]',
  'md:text-[24px]',
  'lg:text-[24px]',
  'text-[#000000]',
  'lg:leading-[31.2px]',
  'md:leading-[31.2px]',
  'xs:leading-[20.8px]',
];
_defaultTheme.components.productCard.description = ['text-[#69676D]'];

_defaultTheme.components.productCard.size.lg = ['w-48', 'md:w-64'];
// _defaultTheme.components.productCard.size.md = ['w-[184px]', 'h-[370px]', 'md:w-[463px]', 'md:h-[775px]'];
_defaultTheme.components.productCard.size.md = ['w-full', 'h-full', 'md:max-w-[463px]'];
_defaultTheme.typography.variants.h3 = ['text-[24px]', 'lg:text-[36px]', 'font-bold'];
_defaultTheme.typography.variants.h5 = ['text-[20px]', 'lg:text-[24px]', 'font-bold'];
//_defaultTheme.components.productCard.price = ['text-[14px]', 'md:text-[9px]', 'lg:text-[18px]'];

_defaultTheme.components.footer.topLinks = ['text-white', 'hover:text-primary-200', 'text-[16px]', 'md:text-[20px]'];
_defaultTheme.components.footer.bottomLinks = ['text-white', 'hover:text-primary-200', 'text-[12px]', 'md:text-[16px]'];
_defaultTheme.components.incentive.title = ['font-bold', 'text-[24px]', 'sm:text-[36px]', 'text-center'];

_defaultTheme.components.incentive.panelTitle = [
  'font-bold',
  'text-white',
  'text-[14px]',
  'tracking-[.15em]',
  'uppercase',
  'lg:w-full',
];

_defaultTheme.components.incentive.panelDescription = ['text-white', 'lg:leading-6', 'sm:leading-5'];

_defaultTheme.components.step.selected = [
  'relative',
  'top-px',
  'text-black',
  'text-[11px]',
  'lg:text-sm',
  'font-semibold',
  'uppercase',
  'mx-4',
  'lg:mx-[26px]',
  'my-2',
  'tracking-wide',
];
_defaultTheme.components.step.unselected = [
  'relative',
  'top-px',
  'text-gray-400',
  'text-[11px]',
  'lg:text-sm',
  'font-semibold',
  'uppercase',
  'mx-4',
  'lg:mx-[26px]',
  'my-2',
  'tracking-wide',
];

_defaultTheme.components.newsLetter.title = [
  'text-black',
  'sm:text-[24px]',
  'text-[20px]',
  'font-normal',
  'leading-7',
  'sm:leading-8',
  'sm:text-left',
  'lg:w-[363px]',
  'lg:h-[62px]',
  'sm:h-[74px]',
  'sm:w-[222px]',
  'w-[311px]',
  'h-[56px]',
  'text-center',
];

_defaultTheme.components.newsLetter.emailInput = [
  'rounded-none',
  'lg:w-[335px]',
  'sm:h-[42px]',
  'sm:w-2xl',
  'w-[176px]',
  'h-[43px]',
];

_defaultTheme.components.newsLetter.emailInputError = ['font-light', 'text-red-700', 'mt-1', 'flex', 'text-[12px]'];

_defaultTheme.components.newsLetter.helpingText = [
  'text-gray-500',
  'font-normal',
  'text-[12px]',
  'leading-[19.2px]',
  'pt-1',
  'text-[#69676D]',
  'col-span-4',
  'w-[335px]',
  'h-[38px]',
];

_defaultTheme.components.newsLetter.signUpButton = [
  'mr-2',
  'w-[149px]',
  'sm:h-[42px]',
  'h-[43px]',
  'sm:-ml-1',
  '-ml-3',
];

_defaultTheme.components.subscriptionConfirmation.title = [
  'text-black',
  'sm:text-[24px]',
  'text-[22px]',
  'font-bold',
  'text-center',
  'w-[314px]',
  'h-[31px]',
  'leading-8',
  '-m-1',
];

_defaultTheme.components.subscriptionConfirmation.icon = ['h-[34px]', 'w-[34px]'];

_defaultTheme.components.subscriptionConfirmation.helpingText = [
  'text-center',
  'text-[16px]',
  'leading-6',
  'w-[352px]',
  'sm:w-[452px]',
  'h-[37px]',
  'text-[#454348]',
];

// Product card styling
_defaultTheme.components.infoCard.contentWrapper = ['py-6', 'px-4', 'md:pr-7', 'lg:pr-11'];
_defaultTheme.components.infoCard.title = [
  'mb-4',
  'ml-4',
  'lg:mb-4',
  'text-gray-700',
  'font-bold',
  'text-2xl',
  'md:text-4xl',
  'pr-6',
];
_defaultTheme.components.infoCard.root = ['bg-white'];
_defaultTheme.components.infoField.root = ['mr-0]'];
_defaultTheme.components.infoField.fieldValue = ['pt-2', 'text-sm', 'md:text-base'];

export const defaultTheme: DesignTheme = _defaultTheme;
