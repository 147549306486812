import { PropsWithChildren, createContext, useContext } from 'react';
import { SWRConfig } from 'swr';
import { FrontasticState, getFrontasticState } from './FrontasticState';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import DarkModeProvider from '../dark-mode';

const initialState: FrontasticState = {
  useCart: {} as any,
  useAccount: {} as any,
  useWishlist: {} as any,
  useAdyen: {} as any,
  useAzure: {} as any,
  useBlobStorage: {} as any,
  useOrder: {} as any,
  useProducts: {} as any,
  useKlaviyo: {} as any,
};

export const FrontasticContext = createContext<FrontasticState>(initialState);

type FrontasticProviderProps = PropsWithChildren;

export const FrontasticProvider = ({ children }: FrontasticProviderProps) => {
  const state: FrontasticState = getFrontasticState();
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <DarkModeProvider>
        <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
      </DarkModeProvider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useCart = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useCart;
};

export const useAccount = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useAccount;
};

export const useWishlist = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useWishlist;
};

export const useAdyen = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useAdyen;
};

export const useAzure = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useAzure;
};

export const useBlobStorage = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useBlobStorage;
};

export const useOrder = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useOrder;
};

export const useProducts = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useProducts;
};

export const useKlaviyo = () => {
  const context = useContext(FrontasticContext);
  checkContext(context);
  return context.useKlaviyo;
};
